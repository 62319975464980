import React, { Component } from "react";
import MainBanner from "./MainBanner";
import Packages from "./Packages";
import Destinations from "./Destinations";
import Blog from "./Blog";
import Features from "./Features";
import Review from "./Review";
import "./style.css";
import Achievement from "./Achievement";
import { Carousel } from "react-responsive-carousel";
import Img16 from "../../../assets/images/carousel-img/pic27.jpeg";

import Img20 from "../../../assets/images/carousel-img/pic31.jpg";
// import Img21 from "../../../assets/images/carousel-img/pic32.jpg";
import Img22 from "../../../assets/images/carousel-img/pic33.jpg";
import Img23 from "../../../assets/images/carousel-img/pic34.jpg";
import Img1 from "../../../assets/images/carousel-img/pic1.jpg";
import Img2 from "../../../assets/images/carousel-img/pic2.jpg";
import Img3 from "../../../assets/images/carousel-img/pic3.jpg";
import Img4 from "../../../assets/images/carousel-img/pic5.jpg";

import Img6 from "../../../assets/images/carousel-img/pic7.jpg";

import Img8 from "../../../assets/images/carousel-img/pic10.jpg";
import Img9 from "../../../assets/images/carousel-img/pic13.jpg";
import Img10 from "../../../assets/images/carousel-img/pic17.jpg";
import Img11 from "../../../assets/images/carousel-img/pic22.jpg";
import Img12 from "../../../assets/images/carousel-img/pic23.jpg";
// import Img13 from "../../../assets/images/carousel-img/pic24.jpg";
import Img14 from "../../../assets/images/carousel-img/pic25.jpg";
import Img15 from "../../../assets/images/carousel-img/pic26.jpg";

import Img46 from "../../../assets/images/carousel-img/35.jpg";
import Img47 from "../../../assets/images/carousel-img/36.jpg";

import Img49 from "../../../assets/images/carousel-img/38.jpg";
import Img50 from "../../../assets/images/carousel-img/39.jpg";


import Img25 from "../../../assets/images/carousel-img/42.jpg";

import Img27 from "../../../assets/images/carousel-img/44.jpg";
import Img28 from "../../../assets/images/carousel-img/45.jpg";
import Img29 from "../../../assets/images/carousel-img/46.jpg";
import Img30 from "../../../assets/images/carousel-img/47.jpg";

import Img32 from "../../../assets/images/carousel-img/49.jpg";
import Img33 from "../../../assets/images/carousel-img/50.jpg";
import Img34 from "../../../assets/images/carousel-img/56.jpg";
import Img35 from "../../../assets/images/carousel-img/52.jpg";
import Img36 from "../../../assets/images/carousel-img/53.jpg";



import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./style.css"



//Define Default Content
class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        {/* Start Preloader Area */}
        <div className="preloader">
          <div className="loader loader1">
            <span style={{ "--i": 1 }} />
            <span style={{ "--i": 2 }} />
            <span style={{ "--i": 3 }} />
            <span style={{ "--i": 4 }} />
            <span style={{ "--i": 5 }} />
            <span style={{ "--i": 6 }} />
            <span style={{ "--i": 7 }} />
            <span style={{ "--i": 8 }} />
            <span style={{ "--i": 9 }} />
            <span style={{ "--i": 10 }} />
            <span style={{ "--i": 11 }} />
            <span style={{ "--i": 12 }} />
            <span style={{ "--i": 13 }} />
            <span style={{ "--i": 14 }} />
            <span style={{ "--i": 15 }} />
            <span style={{ "--i": 16 }} />
            <span style={{ "--i": 17 }} />
            <span style={{ "--i": 18 }} />
            <span style={{ "--i": 19 }} />
            <span style={{ "--i": 20 }} />
            <div className="rocket" />
          </div>
        </div>
        {/* End Preloader Area */}

        {/*---------Start Imported All Sections-----------*/}
        <MainBanner />

        <Packages />
        {/* <Destinations /> */}
        <div>
          <h2 style={{ textAlign: "center", marginTop: "10vw" }}><b>Don't miss out on our recent photos</b></h2>

          <div style={{ display: "flex" }} className=" Carousel col-lg-12 col-md-12 ">

            <div className="container">

              <div style={{ width: "90%", marginLeft: "3vw", }}
                className=" Carousel col-md-12 col-xs-12 col-lg-6  mt-50">

                <Carousel showThumbs={false} autoPlay infiniteLoop >


                  <div className="corouselImg">
                    <img src={Img16} />

                  </div>
                
                  
                 
                  <div className="corouselImg">
                    <img src={Img20} />

                  </div >
                  {/* <div>
                      <img src={Img21} />
                    
                    </div> */}
                  <div className="corouselImg">
                    <img src={Img22} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img23} />

                  </div>


                  <div className="corouselImg">
                    <img src={Img1} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img2} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img3} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img4} />

                  </div>
                
                  <div className="corouselImg">
                    <img src={Img6} />

                  </div>
                 
                  <div className="corouselImg">
                    <img src={Img8} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img9} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img10} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img11} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img12} />

                  </div>
                  {/* <div>
                      <img src={Img13} />
                     
                    </div> */}
                  <div className="corouselImg">
                    <img src={Img14} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img15} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img46} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img47} />

                  </div>
                 
                  <div className="corouselImg">
                    <img src={Img49} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img50} />

                  </div>
                  
                 
                  <div className="corouselImg">
                    <img src={Img25} />

                  </div>
               
                  <div className="corouselImg">
                    <img src={Img27} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img28} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img29} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img30} />

                  </div>
                
                  <div className="corouselImg">
                    <img src={Img32} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img33} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img34} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img35} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img36} />

                  </div>
                
                 
                </Carousel>
              </div>

            </div>
            <div style={{ marginTop: "7vw" }} className="Carousel col-md-12 col-xs-12 col-lg-6 mt-50">
              <div>
                <p style={{ marginLeft: "3vw", marginRight: "3vw" }}>Our website's recent photographs section showcases the most recent images from our tours and events. From breathtaking landscapes to exciting group activities, this section is constantly updated with fresh content for our visitors to enjoy</p>
                <p style={{ marginLeft: "3vw", marginTop: "2vw", marginRight: "3vw" }}>We invite you to take a look and get a sense of the adventures we offer in Saudi Arabia. Whether you're planning a vacation or just looking for a little inspiration, we hope these photographs will give you a taste of what we have to offer at SJH Tours.</p>
              </div>
            </div>
          </div>
          <Achievement />
          <Review />
          {/* <Features /> */}
          {/* <Blog /> */}
          {/*---------End Imported All Sections-----------*/}

        </div>
      </div>

    );
  }
}

export default HomePage;
