import React, { Component } from 'react';
import Headers from './common/headers';
import Footers from './common/footers';
import { ToastProvider } from 'react-toast-notifications';

//For Others route calling this layout
class Layout extends Component {
  render() {
    return (
      <>
        <ToastProvider
          placement='bottom-center'
          autoDismissTimeout={2000}
          autoDismiss={true}
        >
          <Headers />
          {this.props.children}
          <Footers />
        </ToastProvider>
      </>
    );
  }
}

export default Layout;
