import React, { Component } from "react";
import Partner1 from "../../../assets/bank.jfif";
import Partner2 from "../../../assets/bank3.jfif";
import Partner3 from "../../../assets/holy1.jfif";
import Partner4 from "../../../assets/tourhq.jfif";
import Partner5 from "../../../assets/millan.jfif";

import Partner7 from "../../../assets/habitas.jfif";
import Partner8 from "../../../assets/seera.jfif";
import Partner9 from "../../../assets/shadan.jfif";
import Partner10 from "../../../assets/saha.jfif";
import Partner11 from "../../../assets/hayat.jfif";
import Partner13 from "../../../assets/neom.jfif";
import Partner12 from "../../../assets/trip1.jfif";
import Partner6 from "../../../assets/Logo (1).png";

import Partner14 from "../../../assets/saudi-arabia-vision-2030-logo-vector.png";
import Partner15 from "../../../assets/1512776.jpg";
import Partner16 from "../../../assets/sta-logo.png";
import Partner17 from "../../../assets/sudia-arabia.jpg";
import Partner18 from "../../../assets/rooh.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
class Achievement extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const reviewOptions = {
      stagePadding: 10,
      items: 3,
      loop: true,
      margin: 15,
      smartSpeed: 1500,
      autoplay: true,
      dots: true,
      nav: true,
      navText: [
        "<i class='bx bx-chevron-left' ></i>",
        "<i class='bx bx-chevron-right'></i>",
      ],
      responsive: {
        0: {
          items: 1,
          nav: false,
          dots: false,
        },
        800: {
          items: 2,
          nav: false,
          dots: false,
        },
        1000: {
          items: 3,
          dots: true,
          nav: false,
          loop: true,
        },
      },
    };
    return (
      <>
        {/* =============== achievement area start =============== */}
        <div className="review-area mt-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="section-head pb-40">
                  <h5
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      fontSize: "30px",
                    }}
                  >
                    Our Partners And Clients
                  </h5>
                  {/* <h2>What Oue Traveller Say About Us</h2> */}
                </div>
              </div>
            </div>
            <OwlCarousel
              className="review-slider owl-carousel"
              {...reviewOptions}
            >
              <div className="review-card ">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={Partner14}
                    alt=""
                    style={{ height: "130px", width: "200px" }}
                  />
                </div>
                <div className="reviewer-info">
                  <h3>Vision 2030<br></br> Kindom of Saudi Arabia</h3>
                </div>
              </div>
              <div className="review-card ">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={Partner15}
                    alt=""
                    style={{ height: "130px", width: "200px" }}
                  />
                </div>
                <div className="reviewer-info">
                  <h3>Saudi Commission for <br></br>Tourism & National Heritage </h3>
                </div>
              </div>
              <div className="review-card ">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={Partner16}
                    alt=""
                    style={{ height: "130px", width: "200px" }}
                  />
                </div>
                <div className="reviewer-info">
                  <h3>Saudi Tourism Authority</h3>
                </div>
              </div>
              <div className="review-card ">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={Partner17}
                    alt=""
                    style={{ height: "130px", width: "200px" }}
                  />
                </div>
                <div className="reviewer-info">
                  <h3>Ministry of Tourism</h3>
                </div>
              </div>
              <div className="review-card ">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={Partner18}
                    alt=""
                    style={{ height: "130px", width: "200px" }}
                  />
                </div>
                <div className="reviewer-info">
                  <h3>Saudi Spirit</h3>
                </div>
              </div>

              <div className="review-card ">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={Partner1}
                    alt=""
                    style={{ height: "130px", width: "200px" }}
                  />
                </div>
                <div className="reviewer-info">
                  <h3>Swiss In Tabuk</h3>
                </div>
              </div>
              <div className="review-card">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={Partner2}
                    alt=""
                    style={{
                      height: "130px",
                      width: "130px",
                    }}
                  />
                </div>
                <div className="reviewer-info">
                  <h3>Bondai</h3>
                </div>
              </div>
              <div className="review-card">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={Partner3}
                    alt=""
                    style={{
                      height: "130px",
                      width: "130px",
                    }}
                  />
                </div>
                <div className="reviewer-info">
                  <h3>Holiday Inn</h3>
                </div>
              </div>
              <div className="review-card ">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={Partner4}
                    alt=""
                    style={{
                      height: "130px",
                      width: "130px",
                    }}
                  />
                </div>
                <div className="reviewer-info">
                  <h3>tourHQ</h3>
                </div>
              </div>
              <div className="review-card ">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={Partner5}
                    alt=""
                    style={{
                      height: "100px",
                      width: "130px",
                    }}
                  />
                </div>
                <div className="reviewer-info">
                  <h3>Millennium </h3>
                  <p>Hotels and Resorts</p>
                </div>
              </div>
              <div className="review-card ">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={Partner6}
                    alt=""
                    style={{
                      height: "150px",
                      width: "200px",
                    }}
                  />
                </div>
                <div className="reviewer-info">
                  <h3>Dyafat</h3>
                </div>
              </div>
              <div className="review-card ">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={Partner7}
                    alt=""
                    style={{
                      height: "130px",
                      width: "130px",
                    }}
                  />
                </div>
                <div className="reviewer-info">
                  <h3>Habitas</h3>
                </div>
              </div>
              <div className="review-card ">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={Partner8}
                    alt=""
                    style={{
                      height: "130px",
                      width: "200px",
                    }}
                  />
                </div>
                <div className="reviewer-info">
                  <h3>Seera</h3>
                </div>
              </div>
              <div className="review-card ">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={Partner9}
                    alt=""
                    style={{
                      height: "130px",
                      width: "200px",
                    }}
                  />
                </div>
                <div className="reviewer-info">
                  <h3>Shaden Resort</h3>
                </div>
              </div>
              <div className="review-card ">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={Partner10}
                    alt=""
                    style={{
                      height: "130px",
                      width: "200px",
                    }}
                  />
                </div>
                <div className="reviewer-info">
                  <h3>Sahary Resort</h3>
                </div>
              </div>
              <div className="review-card ">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={Partner11}
                    alt=""
                    style={{
                      height: "130px",
                      width: "200px",
                    }}
                  />
                </div>
                <div className="reviewer-info">
                  <h3>Hayat Haql</h3>
                  <p>Hotel And Marina</p>
                </div>
              </div>
              <div className="review-card ">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={Partner12}
                    alt=""
                    style={{
                      height: "130px",
                      width: "200px",
                    }}
                  />
                </div>
                <div className="reviewer-info">
                  <h3 style={{ marginTop: "2rem" }}>Tripadvisor</h3>
                </div>
              </div>
              <div className="review-card ">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={Partner13}
                    alt=""
                    style={{
                      height: "130px",
                      width: "200px",
                    }}
                  />
                </div>
                <div className="reviewer-info">
                  <h3 style={{ marginTop: "1rem" }}>Neom</h3>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
        {/* =============== achievement area end =============== */}
      </>
    );
  }
}

export default Achievement;
