import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import Ticktok from "../../assets/logos/ticktok.png";

//Import Image

import secondLogo from "../../assets/images/logoSjh.png";
import LogoSJH from "../../assets/images/logoSjh.png";



class Headers extends Component {
  scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  googleTranslateElementInit() {
    new window.google.translate.TranslateElement(
      {
        includedLanguages: "ar,en,es,it,fr,de,ur,ru,zh-CN",
        layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT,
        disableTranslateElement: true,
      },
      "google_translate_element"
    );
  }
  componentDidMount() {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = this.googleTranslateElementInit;
  }
  render() {
    return (
      <>
        {/* =============== Topbar area start =============== */}
        <div style={{ userSelect: "text" }} className="topbar-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-5 tob-contact-row">
                <div className="topbar-contact">
                  <ul>
                    <li>
                      <i className="bx bxs-phone" />
                      <a id="abc" href="tel:+966500005114">+966500005114</a>
                    </li>

                    <li>
                      <i className="bx bxs-envelope" />
                      <a href="info@sjhtours.com">info@sjhtours.com</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6 col-6 mobile_view">
                <div className="topbar-social">
                  <ul>
                    <li>
                      <a
                        href={"https://www.instagram.com/sjhtours/"}
                        target="_blank"
                      >
                        <i className="bx bxl-instagram" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={"https://t.snapchat.com/FVjtMRLn"}
                        target="_blank"
                      >
                        <i className="bx bxl-snapchat" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={"https://www.facebook.com/sjhksasocial/"}
                        target="_blank"
                      >
                        <i className="bx bxl-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href={"https://twitter.com/sjhtours"} target="_blank">
                        <i className="bx bxl-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href={"https://wa.me/966500005114"} target="_blank">
                        <i className="bx bxl-whatsapp" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={"https://www.tiktok.com/@sjhtours?lang=ar"}
                        target="_blank"
                      >
                        <img
                          src={Ticktok}
                          style={{
                            width: "13px",
                            height: "13px",
                            marginBottom: "3px",
                          }}
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-6 "
                style={{ height: "35px" }}
              >
                <div id="google_translate_element"></div>
              </div>
            </div>
          </div>
        </div>

        {/* =============== Topbar area end =============== */}

        {/* ===============  header area start =============== */}
        <header>
          <div className="header-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-2 col-md-12 col-sm-12 col-xs-12">
                  <div className="navbar-wrap">
                    <div className="logo d-flex justify-content-between">
                      <Link
                        to={`${process.env.PUBLIC_URL}/`}
                        className="navbar-brand"
                        onClick={this.scrollTop}
                      >
                        <img
                          src={LogoSJH}
                          alt=""
                          style={{ width: "200px", height: "100px" }}
                        />
                      </Link>
                    </div>
                    <div className="navbar-icons">
                      {/* <div className="searchbar-open">
                        <i className="flaticon-magnifier" />
                      </div> */}
                      <div className="user-dropdown-icon">
                        <div className="account-dropdown">
                          <ul>
                            <li className="account-el">
                              <i className="bx bx-user-pin" />
                              <Link to={"#"}>Sign in</Link>
                            </li>
                            <li className="account-el">
                              <i className="bx bxs-user-account" />
                              <Link to={"#"}>My Account</Link>
                            </li>
                            <li className="account-el">
                              <i className="bx bx-extension" />
                              <Link to={"#"}>Settings</Link>
                            </li>
                            <li className="account-el">
                              <i className="bx bx-log-in-circle" />
                              <Link to={"#"}>Log out</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="mobile-menu d-flex ">
                        <div className="top-search-bar m-0 d-block d-xl-none"></div>
                        <Link to={"#"} className="hamburger d-block d-xl-none">
                          <span className="h-top" />
                          <span className="h-middle" />
                          <span className="h-bottom" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                  <nav className="main-nav">
                    <div className="navber-logo-sm">
                      <img
                        src={secondLogo}
                        alt=""
                        style={{ width: "170px", height: "40px" }}
                        className="img-fluid"
                      />
                    </div>
                    <ul>
                      <li>
                        <Link to={`${process.env.PUBLIC_URL}/`}>Home</Link>
                        {/* <i className="fl flaticon-plus">+</i>
                        <ul className="sub-menu">
                          <li>
                            <NavLink
                              activeClassName="active"
                              to={`${process.env.PUBLIC_URL}/`}
                              className="sub-item"
                              onClick={this.scrollTop}
                            >
                              Home Page 1
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              activeClassName="active"
                              to={`${process.env.PUBLIC_URL}/home-page-2`}
                              className="sub-item"
                              onClick={this.scrollTop}
                            >
                              Home Page 2
                            </NavLink>
                          </li>
                        </ul> */}
                      </li>
                      <li>
                        <NavLink
                          activeClassName="active"
                          to={`${process.env.PUBLIC_URL}/about-us`}
                          onClick={this.scrollTop}
                        >
                          About us
                        </NavLink>
                      </li>
                      {/* <li>
                        <NavLink
                          activeClassName="active"
                          to={`${process.env.PUBLIC_URL}/destination`}
                          onClick={this.scrollTop}
                        >
                          Destinations
                        </NavLink>
                      </li> */}
                      <li>
                        <NavLink
                          activeClassName="active"
                          to={`${process.env.PUBLIC_URL}/package`}
                          onClick={this.scrollTop}
                        >
                          Tour Packages
                        </NavLink>
                      </li>

                      {/* <li className="has-child-menu">
                        <Link to={"#"}>Blogs</Link>
                        <i className="fl flaticon-plus">+</i>
                        <ul className="sub-menu">
                          <li>
                            <NavLink
                              activeClassName="active"
                              to={`${process.env.PUBLIC_URL}/blog`}
                              className="sub-item"
                              onClick={this.scrollTop}
                            >
                              Blog page
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              activeClassName="active"
                              to={`${process.env.PUBLIC_URL}/blog-sidebar`}
                              className="sub-item"
                              onClick={this.scrollTop}
                            >
                              Blog sidebar
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              activeClassName="active"
                              to={`${process.env.PUBLIC_URL}/blog-standard`}
                              className="sub-item"
                              onClick={this.scrollTop}
                            >
                              Blog standard
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              activeClassName="active"
                              to={`${process.env.PUBLIC_URL}/blog-details`}
                              className="sub-item"
                              onClick={this.scrollTop}
                            >
                              blog-details{" "}
                            </NavLink>
                          </li>
                        </ul>
                      </li> */}
                      {/* <li className="has-child-menu">
                        <Link to={"#"}>Pages</Link>
                        <i className="fl flaticon-plus">+</i>
                        <ul className="sub-menu">
                          <li>
                            <NavLink
                              activeClassName="active"
                              to={`${process.env.PUBLIC_URL}/gallary`}
                              className="sub-item"
                              onClick={this.scrollTop}
                            >
                              gallary page
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              activeClassName="active"
                              to={`${process.env.PUBLIC_URL}/guide`}
                              className="sub-item"
                              onClick={this.scrollTop}
                            >
                              guide page
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              activeClassName="active"
                              to={`${process.env.PUBLIC_URL}/destination`}
                              className="sub-item"
                              onClick={this.scrollTop}
                            >
                              destination page
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              activeClassName="active"
                              to={`${process.env.PUBLIC_URL}/404`}
                              className="sub-item"
                              onClick={this.scrollTop}
                            >
                              404 Page
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              activeClassName="active"
                              to={`${process.env.PUBLIC_URL}/faq`}
                              className="sub-item"
                              onClick={this.scrollTop}
                            >
                              FAQ page
                            </NavLink>
                          </li>
                        </ul>
                      </li> */}

                      <li>
                        <NavLink
                          activeClassName="active"
                          to={`${process.env.PUBLIC_URL}/contact`}
                          onClick={this.scrollTop}
                        >
                          Contact Us
                        </NavLink>
                      </li>
                    </ul>
                    {/* <div className="navbar-icons-2">
                      <div className="searchbar-open">
                        <i className="flaticon-magnifier" />
                      </div>
                      <div className="user-dropdown-icon">
                        <i className="flaticon-user" />
                        <div className="account-dropdown">
                          <ul>
                            <li className="account-el">
                              <i className="bx bx-user-pin" />
                              <Link to={`#`}>Sign in</Link>
                            </li>
                            <li className="account-el">
                              <i className="bx bxs-user-account" />
                              <Link to={`#`}>My Account</Link>
                            </li>
                            <li className="account-el">
                              <i className="bx bx-extension" />
                              <Link to={`#`}>Settings</Link>
                            </li>
                            <li className="account-el">
                              <i className="bx bx-log-in-circle" />
                              <Link to={`#`}>Log out</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div> */}
                    <div className="sidebar-contact">
                      <ul>
                        <li className="sidebar-single-contact">
                          <i className="bx bxs-phone" />
                          <Link to={`tel:+960500005114`}>+960500005114</Link>
                        </li>
                        <li className="sidebar-single-contact">
                          <i className="bx bxs-envelope" />
                          <Link to={`mailto:info@sjhtours.com`}>
                            info@sjhtours.com
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </div>

            <form>
              <div className="main-searchbar">
                <div className="searchbar-close">
                  <i className="bx bx-x" />
                </div>
                <input type="text" placeholder="Search Here......" />
                <div className="searchbar-icon">
                  <i className="bx bx-search" />
                </div>
              </div>
            </form>
          </div>
        </header>
        {/* ===============  header area end =============== */}
      </>
    );
  }
}

export default Headers;
