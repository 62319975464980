import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import { Link } from "react-router-dom";
const AboutUs = () => {
  const { addToast } = useToasts();
  const [disabled, setDisabled] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({});
  useEffect(() => {
    scrollTop();
  }, []);

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const submitData = (data) => {
    setDisabled(true);

    let recipient = "";

    if (window.location.href.includes("sjhtours")) {
      recipient = "info@sjhtours.com";
    } else {
      recipient = "bismaasarvar123@gmail.com"; // Testing Email
    }
    let reqData = {
      to: recipient,
      name: data.name,
      email: data.email,
      subject: data.subject,
      message: `<!DOCTYPE html>
        <html>
          <head>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
            <title> Travel Email</title>
            <style>
              img {
                border: none;
                -ms-interpolation-mode: bicubic;
                max-width: 100%;
              }
              body {
                background-color: #f6f6f6;
                font-family: sans-serif;
                -webkit-font-smoothing: antialiased;
                font-size: 14px;
                line-height: 1.4;
                margin: 0;
                padding: 0;
                -ms-text-size-adjust: 100%;
                -webkit-text-size-adjust: 100%;
              }
              table {
                border-collapse: separate;
                mso-table-lspace: 0pt;
                mso-table-rspace: 0pt;
                width: 100%;
              }
              table td {
                font-family: sans-serif;
                font-size: 14px;
                vertical-align: top;
              } /* ------------------------------------- BODY & CONTAINER ------------------------------------- */
              .body {
                background-color: #f6f6f6;
                width: 100%;
              } /* Set a max-width, and make it display as block so it will automatically stretch to that width, but will also shrink down on a phone or something */
              .container {
                display: block;
                margin: 0 auto !important; /* makes it centered */
                max-width: 580px;
                padding: 10px;
                width: 580px;
              }
              .content {
                box-sizing: border-box;
                display: block;
                margin: 0 auto;
                max-width: 580px;
                padding: 10px;
              }
              .main {
                background: #ffffff;
                border-radius: 3px;
                width: 100%;
              }
              .wrapper {
                box-sizing: border-box;
                padding: 20px;
              }
              .content-block {
                padding-bottom: 10px;
                padding-top: 10px;
              }
              .footer {
                clear: both;
                margin-top: 10px;
                text-align: center;
                width: 100%;
              }
              .footer td,
              .footer p,
              .footer span,
              .footer a {
                color: #999999;
                font-size: 12px;
                text-align: center;
              }
              h1,
              h2,
              h3,
              h4 {
                color: #000000;
                font-family: sans-serif;
                font-weight: 400;
                line-height: 1.4;
                margin: 0;
                margin-bottom: 30px;
              }
              h1 {
                font-size: 35px;
                font-weight: 300;
                text-align: center;
                text-transform: capitalize;
              }
              p,
              ul,
              ol {
                font-family: sans-serif;
                font-size: 14px;
                font-weight: normal;
                margin: 0;
                margin-bottom: 15px;
              }
              p li,
              ul li,
              ol li {
                list-style-position: inside;
                margin-left: 5px;
              }
              a {
                color: #3498db;
                text-decoration: underline;
              }
              .btn {
                box-sizing: border-box;
                width: 100%;
              }
              .btn > tbody > tr > td {
                padding-bottom: 15px;
              }
              .btn table {
                width: auto;
              }
              .btn table td {
                background-color: #ffffff;
                border-radius: 5px;
                text-align: center;
              }
              .btn a {
                background-color: #ffffff;
                border: solid 1px #3498db;
                border-radius: 5px;
                box-sizing: border-box;
                color: #3498db;
                cursor: pointer;
                display: inline-block;
                font-size: 14px;
                font-weight: bold;
                margin: 0;
                padding: 12px 25px;
                text-decoration: none;
                text-transform: capitalize;
              }
              .btn-primary table td {
                background-color: #3498db;
              }
              .btn-primary a {
                background-color: #3498db;
                border-color: #3498db;
                color: #ffffff;
              }
              .last {
                margin-bottom: 0;
              }
              .first {
                margin-top: 0;
              }
              .align-center {
                text-align: center;
              }
              .align-right {
                text-align: right;
              }
              .align-left {
                text-align: left;
              }
              .clear {
                clear: both;
              }
              .mt0 {
                margin-top: 0;
              }
              .mb0 {
                margin-bottom: 0;
              }
              .preheader {
                color: transparent;
                display: none;
                height: 0;
                max-height: 0;
                max-width: 0;
                opacity: 0;
                overflow: hidden;
                mso-hide: all;
                visibility: hidden;
                width: 0;
              }
              .powered-by a {
                text-decoration: none;
              }
              hr {
                border: 0;
                border-bottom: 1px solid #f6f6f6;
                margin: 20px 0;
              }
              @media only screen and (max-width: 620px) {
                table.body h1 {
                  font-size: 28px !important;
                  margin-bottom: 10px !important;
                }
                table.body p,
                table.body ul,
                table.body ol,
                table.body td,
                table.body span,
                table.body a {
                  font-size: 16px !important;
                }
                table.body .wrapper,
                table.body .article {
                  padding: 10px !important;
                }
                table.body .content {
                  padding: 0 !important;
                }
                table.body .container {
                  padding: 0 !important;
                  width: 100% !important;
                }
                table.body .main {
                  border-left-width: 0 !important;
                  border-radius: 0 !important;
                  border-right-width: 0 !important;
                }
                table.body .btn table {
                  width: 100% !important;
                }
                table.body .btn a {
                  width: 100% !important;
                }
                table.body .img-responsive {
                  height: auto !important;
                  max-width: 100% !important;
                  width: auto !important;
                }
              }
              @media all {
                .ExternalClass {
                  width: 100%;
                }
                .ExternalClass,
                .ExternalClass p,
                .ExternalClass span,
                .ExternalClass font,
                .ExternalClass td,
                .ExternalClass div {
                  line-height: 100%;
                }
                .apple-link a {
                  color: inherit !important;
                  font-family: inherit !important;
                  font-size: inherit !important;
                  font-weight: inherit !important;
                  line-height: inherit !important;
                  text-decoration: none !important;
                }
                #MessageViewBody a {
                  color: inherit;
                  text-decoration: none;
                  font-size: inherit;
                  font-family: inherit;
                  font-weight: inherit;
                  line-height: inherit;
                }
                .btn-primary table td:hover {
                  background-color: #34495e !important;
                }
                .btn-primary a:hover {
                  background-color: #34495e !important;
                  border-color: #34495e !important;
                }
              }
        </style>
        </head>
        <body>
          <span class="preheader"
            >This is preheader text. Some clients will show this text as a
            preview.</span
          >
          <table
            role="presentation"
            border="0"
            cellpadding="0"
            cellspacing="0"
            class="body"
          >
            <tr>
              <td>&nbsp;</td>
              <td class="container">
                <div class="content">
                  <table role="presentation" class="main">
                    <tr>
                      <td class="wrapper">
                        <table
                          role="presentation"
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                        >
                          <tr>
                          <tr>
                          <td>
                            <p>I am ${data.name} ,</p>
                            <br/>
                            <p><b>Subject</b>: ${data.subject},</p>
                            <br/>
                            <p>
                              ${data.message}
                            </p>
                            <br/>
                            <span>Yours Sincerely,</span><br/>
                            <span><b>${data.name}</b></span><br/>
                            <span>Email: ${data.email},</span><br/>
                            <span>Phone: ${data.phone},</span><br/>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                  <div class="footer">
                    <table
                      role="presentation"
                      border="0"
                      cellpadding="0"
                      cellspacing="0"
                    >
                      <tr>
                        <td class="content-block">
                          <span class="apple-link"
                            >Srinagar, Jammu & Kashmir, India</span
                          >
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td class="content-block powered-by">
                          Powered by
                          <a href="https://redstaglabs.com" target="_blank">Red Stag Labs</a>.
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </td>
              <td>&nbsp;</td>
            </tr>
          </table>
        </body>
      </html>`,
    };

    let req = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(reqData),
    };

    fetch("https://us-central1-sjhtours.cloudfunctions.net/contact-sjh", req)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          addToast("Email is Sent Successfully", { appearance: "success" });
          reset();
          setDisabled(false);
        } else {
          addToast("Error Occured While Sending Email", {
            appearance: "error",
          });
          setDisabled(false);
        }
      });
  };
  // render() {
  return (
    <>
      {/* ===============  breadcrumb area start =============== */}
      <div className="breadcrumb-area-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="breadcrumb-wrap">
                <h2>Contact US</h2>
                <ul className="breadcrumb-links">
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/`}>Home</Link>
                    <i className="bx bx-chevron-right" />
                  </li>
                  <li>Contact Us</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ===============  breadcrumb area end =============== */}
      <div className="contact-wrapper pt-90">
        <div className="contact-cards">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="contact-card">
                  <div className="contact-icon">
                    <i className="flaticon-arrival" />
                  </div>
                  <div className="contact-info">
                    <h5>Address</h5>
                    <p style={{ fontSize: "14px" }}>
                      King Khalid Road, Al Faisaliyah South, Tabuk 47911
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="contact-card">
                  <div className="contact-icon">
                    <i className="flaticon-customer-service" />
                  </div>
                  <div className="contact-info">
                    <h5>Email &amp; Phone</h5>
                    <p>+966500005114 info@sjhtours.com</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="contact-card">
                  <div className="contact-icon">
                    <i className="flaticon-thumbs-up" />
                  </div>
                  <div className="contact-info">
                    <h5>Social Media</h5>
                    <ul className="contact-icons">
                      <li>
                        <a
                          href={"https://www.instagram.com/sjhtours/"}
                          target="_blank"
                        >
                          <i className="bx bxl-instagram" />
                        </a>
                      </li>
                      <li>
                        <a
                          href={"https://www.facebook.com/sjhksasocial/"}
                          target="_blank"
                        >
                          <i className="bx bxl-facebook" />
                        </a>
                      </li>
                      <li>
                        <a
                          href={"https://twitter.com/sjhtours"}
                          target="_blank"
                        >
                          <i className="bx bxl-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href={"https://wa.me/966500005114"} target="_blank">
                          <i className="bx bxl-whatsapp" />
                        </a>
                      </li>
                      <li>
                        <a
                          href={"https://www.tiktok.com/@sjhtours?lang=ar"}
                          target="_blank"
                        >
                          {" "}
                          <i className="bx bxl-tiktok" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="contact-inputs pt-100">
            <div className="row">
              <div className="col-lg-6">
                <div className="contact-details">
                  <h5 className="contact-d-head">Get In Touch</h5>
                  <p>
                    Here, you'll find a variety of contact options, including a
                    phone number, email address, and online form. Simply choose
                    the option that works best for you, and a member of the SJH
                    Tours team will be happy to assist you. Whether you're an
                    experienced traveler or a first-time visitor to Saudi
                    Arabia, SJH Tours is here to help you plan the perfect trip.
                  </p>
                  <ul className="office-clock">
                    <li>
                      <div className="clock-icon">
                        <i className="flaticon-clock-1" />
                      </div>
                      <div className="clock-info">
                        <h5>Open Hour</h5>
                        <p>
                          Sat - Thu At <br /> 10.00Am to 10.00PM
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="clock-icon">
                        <i className="flaticon-clock-1" />
                      </div>
                      <div className="clock-info">
                        <h5>Close Hour</h5>
                        <p>Friday Office Close</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="contact-form">
                  <form onSubmit={handleSubmit(submitData)}>
                    <h5 className="contact-d-head">Contact Us</h5>
                    <div className="row">
                      <div className="col-lg-6">
                        {errors.name && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "10px",
                              mb: 2,
                            }}
                          >
                            Field Is required
                          </p>
                        )}
                        <input
                          {...register("name", { required: true })}
                          id="name"
                          name="name"
                          type="text"
                          placeholder="Your  Name"
                        />
                      </div>
                      <div className="col-lg-6">
                        {errors.subject && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "10px",
                              mb: 2,
                            }}
                          >
                            Field Is required
                          </p>
                        )}
                        <input
                          {...register("subject", { required: true })}
                          id="subject"
                          name="subject"
                          type="text"
                          placeholder="subject"
                        />
                      </div>
                      <div className="col-lg-6">
                        {errors.email && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "10px",
                              mb: 2,
                            }}
                          >
                            Field Is required
                          </p>
                        )}
                        <input
                          {...register("email", { required: true })}
                          id="email"
                          name="email"
                          type="email"
                          placeholder="Your Email"
                        />
                      </div>
                      <div className="col-lg-6">
                        {errors.phone && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "10px",
                              mb: 2,
                            }}
                          >
                            Field Is required
                          </p>
                        )}
                        <input
                          {...register("phone", { required: true })}
                          id="phone"
                          name="phone"
                          type="tel"
                          placeholder="Your Phone"
                        />
                      </div>
                      <div className="col-lg-12">
                        {errors.message && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "10px",
                              mb: 2,
                            }}
                          >
                            Field Is required
                          </p>
                        )}
                        <textarea
                          {...register("message", { required: true })}
                          cols={30}
                          rows={7}
                          name="message"
                          id="message"
                          placeholder="Write Message"
                          defaultValue={""}
                        />
                      </div>
                      <div className="col-lg-12">
                        {/* <button
                              style={{
                                backgroundColor: 'yellow',
                                border: 'none',
                                width: '100%',
                              }}
                              type='submit'
                              value='submit'
                              disabled={disabled}
                              // onClick={onSubmitForm}
                            >
                              Submit
                            </button> */}

                        <input
                        style={{marginBottom: "30px"}}
                          type="submit"
                          disabled={disabled}
                          defaultValue="Submit Now"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
// }

export default AboutUs;
