import React, { Component } from "react";

import HomePage from "../pages/home/HomePage";
import Headers from "../common/headers";
import Footers from "../common/footers";

function MainLayout() {
  return (
    <>
      <Headers />
      <HomePage />
      <Footers />
    </>
  );
  // }
}

export default MainLayout;
