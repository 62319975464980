import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

//Import Images
import review1Img from "../../../assets/review1.jfif";
import review2Img from "../../../assets/review2.jfif";
import review3Img from "../../../assets/review3.jfif";
import review4Img from "../../../assets/review4.jfif";
import review5Img from "../../../assets/review5.jfif";
import review6Img from "../../../assets/review6.jfif";
import review7Img from "../../../assets/review7.jfif";
import review8Img from "../../../assets/review8.jfif";
import review9Img from "../../../assets/review9.jfif";
import review10Img from "../../../assets/review13.png";
import review11Img from "../../../assets/janlee.png";
import review12Img from "../../../assets/Alfonsus Andrianto.png";
import review14Img from "../../../assets/review14.png";
import review15Img from "../../../assets/review15.png";
import review16Img from "../../../assets/review16.png";
class AboutReview extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const reviewOptions = {
      stagePadding: 10,
      items: 3,
      loop: true,
      margin: 15,
      smartSpeed: 200,
      autoplay: false,
      dots: true,
      nav: true,
      navText: [
        "<i class='bx bx-chevron-left' ></i>",
        "<i class='bx bx-chevron-right'></i>",
      ],
      responsive: {
        0: {
          items: 1,
          nav: false,
          dots: false,
        },
        800: {
          items: 2,
          nav: false,
          dots: false,
        },
        1000: {
          items: 3,
          dots: true,
          nav: false,
          loop: true,
        },
      },
    };

    return (
      <>
        {/* =============== Review area start =============== */}
        <div className="review-area mt-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="section-head pb-40">
                  <h5>Testimonials</h5>
                   <h2>What Our Travellers Say About Us</h2> 
                </div>
              </div>
            </div>
            <OwlCarousel
              className="review-slider owl-carousel"
              {...reviewOptions}
            >
               <div className="review-card " style={{ height: "400px" }}>
                <div className="reviewer-img">
                  <img src={review16Img} alt="" className="img-fluid" />
                </div>
                <div
                  className="reviewer-info"
                  style={{ height: "250px", overflowY: "hidden" }}
                >
                  <h3>Mehilin Espin</h3>
                  <h5>Traveller</h5>
                  <p>
                  I traveled to Saudi Arabia with many questions but,  Sjh travels has exceed my expectations!
                    <a
                      href="https://www.google.com/maps/place/%D8%B3%D8%AC%D8%A9+%D9%84%D8%AA%D9%86%D8%B8%D9%8A%D9%85+%D8%A7%D9%84%D8%B1%D8%AD%D9%84%D8%A7%D8%AA%E2%80%AD/@28.3850687,36.5796898,17z/data=!4m8!3m7!1s0x15a9adc5a0547b03:0x95f0d9acf226d283!8m2!3d28.3850687!4d36.5796898!9m1!1b1!16s%2Fg%2F11j3rywbz7"
                      target="_blank"
                    >
                      Read More
                    </a>
                  </p>
                </div>
              </div>
               <div className="review-card " style={{ height: "400px" }}>
                <div className="reviewer-img">
                  <img src={review14Img} alt="" className="img-fluid" />
                </div>
                <div
                  className="reviewer-info"
                  style={{ height: "250px", overflowY: "hidden" }}
                >
                  <h3>Paola Menoscal</h3>
                  <h5>Traveller</h5>
                  <p>
                  Had the most amazing experience with our tour guides! Would highly recommend it.
                    <a
                      href="https://www.google.com/maps/place/%D8%B3%D8%AC%D8%A9+%D9%84%D8%AA%D9%86%D8%B8%D9%8A%D9%85+%D8%A7%D9%84%D8%B1%D8%AD%D9%84%D8%A7%D8%AA%E2%80%AD/@28.3850687,36.5796898,17z/data=!4m8!3m7!1s0x15a9adc5a0547b03:0x95f0d9acf226d283!8m2!3d28.3850687!4d36.5796898!9m1!1b1!16s%2Fg%2F11j3rywbz7"
                      target="_blank"
                    >
                      Read More
                    </a>
                  </p>
                </div>
              </div>

              <div className="review-card " style={{ height: "400px" }}>
                <div className="reviewer-img">
                  <img src={review15Img} alt="" className="img-fluid" />
                </div>
                <div
                  className="reviewer-info"
                  style={{ height: "250px", overflowY: "hidden" }}
                >
                  <h3>Thomas Dias</h3>
                  <h5>Traveller</h5>
                  <p>
                  I’d first off like to say that this company and their services are top tier. 
                    <a
                      href="https://www.google.com/maps/place/%D8%B3%D8%AC%D8%A9+%D9%84%D8%AA%D9%86%D8%B8%D9%8A%D9%85+%D8%A7%D9%84%D8%B1%D8%AD%D9%84%D8%A7%D8%AA%E2%80%AD/@28.3850687,36.5796898,17z/data=!4m8!3m7!1s0x15a9adc5a0547b03:0x95f0d9acf226d283!8m2!3d28.3850687!4d36.5796898!9m1!1b1!16s%2Fg%2F11j3rywbz7"
                      target="_blank"
                    >
                      Read More
                    </a>
                  </p>
                </div>
              </div>

               <div className="review-card " style={{ height: "400px" }}>
                <div className="reviewer-img">
                  <img src={review11Img} alt="" className="img-fluid" />
                </div>
                <div
                  className="reviewer-info"
                  style={{ height: "250px", overflowY: "hidden" }}
                >
                  <h3>Jan Lee</h3>
                  <h5>Traveller</h5>
                  <p>
                  SJH got one good travel planner which is Mr. Zahid. He could solve the problems for us in short period of time.
                    <a
                      href="https://www.google.com/maps/place/%D8%B3%D8%AC%D8%A9+%D9%84%D8%AA%D9%86%D8%B8%D9%8A%D9%85+%D8%A7%D9%84%D8%B1%D8%AD%D9%84%D8%A7%D8%AA%E2%80%AD/@28.3850687,36.5796898,17z/data=!4m8!3m7!1s0x15a9adc5a0547b03:0x95f0d9acf226d283!8m2!3d28.3850687!4d36.5796898!9m1!1b1!16s%2Fg%2F11j3rywbz7"
                      target="_blank"
                    >
                      Read More
                    </a>
                  </p>
                </div>
              </div>

              <div className="review-card " style={{ height: "400px" }}>
                <div className="reviewer-img">
                  <img src={review12Img} alt="" className="img-fluid" />
                </div>
                <div
                  className="reviewer-info"
                  style={{ height: "250px", overflowY: "hidden" }}
                >
                  <h3>Alfonsus Andrianto</h3>
                  <h5>Traveller</h5>
                  <p>
                  Very nice tour. We will do it again with groups
                    <a
                      href="https://www.google.com/maps/place/%D8%B3%D8%AC%D8%A9+%D9%84%D8%AA%D9%86%D8%B8%D9%8A%D9%85+%D8%A7%D9%84%D8%B1%D8%AD%D9%84%D8%A7%D8%AA%E2%80%AD/@28.3850687,36.5796898,17z/data=!4m8!3m7!1s0x15a9adc5a0547b03:0x95f0d9acf226d283!8m2!3d28.3850687!4d36.5796898!9m1!1b1!16s%2Fg%2F11j3rywbz7"
                      target="_blank"
                    >
                      Read More
                    </a>
                  </p>
                </div>
              </div>
               <div className="review-card " style={{ height: "400px" }}>
                <div className="reviewer-img">
                  <img src={review10Img} alt="" className="img-fluid" />
                </div>
                <div
                  className="reviewer-info"
                  style={{ height: "250px", overflowY: "hidden" }}
                >
                  <h3>Tan Kang Han</h3>
                  <h5>Traveller</h5>
                  <p>
                  Our team of six did a trip to KSA end of 2022 and contracted Zahid's SJH Travels in Tabuk to take us around. We did a 3 day trip to Al Ula.
                    <a
                      href="https://www.google.com/maps/contrib/100249406148036972249/reviews/@28.3850687,36.5796898,17z/data=!3m1!4b1!4m3!8m2!3m1!1e1?hl=en-US"
                      target="_blank"
                    >
                      Read More
                    </a>
                  </p>
                </div>
              </div>

              <div className="review-card" style={{ height: "400px" }}>
                <div className="reviewer-img">
                  <img src={review9Img} alt="" className="img-fluid" />
                </div>
                <div className="reviewer-info">
                  <h3>Hawazen AlQadi</h3>
                  <h5>Traveller</h5>
                  <p>
                    The one-day trip to Neom was enjoyable. SJH is keen to make
                    everyone comfortable on the trip.
                    <br />
                    <a
                      href="https://g.page/r/CYPSJvKs2fCVEB0/review"
                      target="_blank"
                    >
                      Read More
                    </a>
                  </p>
                </div>
              </div>

              <div className="review-card" style={{ height: "400px" }}>
                <div className="reviewer-img">
                  <img src={review8Img} alt="" className="img-fluid" />
                </div>
                <div className="reviewer-info">
                  <h3>Federico Ponti</h3>
                  <h5>Traveller</h5>
                  <p>
                    Thanks Sjhtours, you guys have been great all along our
                    seven days of trip.
                    <br />
                    <a
                      href="https://g.page/r/CYPSJvKs2fCVEB0/review"
                      target="_blank"
                    >
                      Read More
                    </a>
                  </p>
                </div>
              </div>
              <div className="review-card ">
                <div className="reviewer-img">
                  <img src={review1Img} alt="" className="img-fluid" />
                </div>
                <div
                  className="reviewer-info"
                  style={{ height: "250px", overflowY: "hidden" }}
                >
                  <h3>Alesia Surnina</h3>
                  <h5>Traveller</h5>
                  <p>
                    To tell the truth, 2 days that we spent with this agency
                    were the best for me during the whole trip to Saudi Arabia.
                    <a
                      href="https://g.page/r/CYPSJvKs2fCVEB0/review"
                      target="_blank"
                    >
                      Read More
                    </a>
                  </p>
                </div>
              </div>
              <div className="review-card">
                <div className="reviewer-img">
                  <img src={review2Img} alt="" className="img-fluid" />
                </div>
                <div
                  className="reviewer-info"
                  style={{ height: "250px", overflow: "hidden" }}
                >
                  <h3>A Hosea</h3>
                  <h5>Traveller</h5>
                  <p>
                    SJH is a second to none Touring company! I highly recommend
                    them for your touring needs and desires .{" "}
                    <a
                      href="https://g.page/r/CYPSJvKs2fCVEB0/review"
                      target="_blank"
                    >
                      Read More
                    </a>
                  </p>
                </div>
              </div>
              <div className="review-card">
                <div className="reviewer-img">
                  <img src={review3Img} alt="" className="img-fluid" />
                </div>
                <div
                  className="reviewer-info"
                  style={{ height: "250px", overflow: "hidden" }}
                >
                  <h3>MAR-BRAVO</h3>
                  <h5>Traveller</h5>
                  <p>
                    We loved the service that SJH tours provided... the tour
                    guide and driver (Ahmed and waad) has knowledgeable friendly
                    and funny.
                    <a
                      href="https://g.page/r/CYPSJvKs2fCVEB0/review"
                      target="_blank"
                    >
                      Read More
                    </a>
                  </p>
                </div>
              </div>

              <div className="review-card" style={{ height: "400px" }}>
                <div className="reviewer-img">
                  <img src={review7Img} alt="" className="img-fluid" />
                </div>
                <div className="reviewer-info">
                  <h3>Rachel Henwood</h3>
                  <h5>Traveller</h5>
                  <p>
                   Everything was perfect. We booked a 4-day trip from Al-Ula to Tabuk.
                    <br />
                    <a
                      href="https://g.page/r/CYPSJvKs2fCVEB0/review"
                      target="_blank"
                    >
                      Read More
                    </a>
                  </p>
                </div>
              </div>
              <div className="review-card ">
                <div className="reviewer-img">
                  <img src={review4Img} alt="" className="img-fluid" />
                </div>
                <div
                  className="reviewer-info"
                  style={{ height: "250px", overflow: "hidden" }}
                >
                  <h3>Crystal Tackaberry</h3>
                  <h5>Traveller</h5>
                  <p>
                    Absolutely amazing tour! SJH tour company and our guide
                    Khalid were incredibly professional and knowledgeable.
                    <a
                      href="https://g.page/r/CYPSJvKs2fCVEB0/review"
                      target="_blank"
                    >
                      Read More
                    </a>
                  </p>
                </div>
              </div>
              <div className="review-card">
                <div className="reviewer-img">
                  <img src={review5Img} alt="" className="img-fluid" />
                </div>
                <div
                  className="reviewer-info"
                  style={{ height: "250px", overflow: "hidden" }}
                >
                  <h3>nis neesa</h3>
                  <h5>Traveller</h5>
                  <p>
                    We did a last minute booking for 3 days tours with sjh tours
                    and their response was very fast and accommodating.{" "}
                    <a
                      href="https://g.page/r/CYPSJvKs2fCVEB0/review"
                      target="_blank"
                    >
                      Read More
                    </a>
                  </p>
                </div>
              </div>
              <div className="review-card">
                <div className="reviewer-img">
                  <img src={review6Img} alt="" className="img-fluid" />
                </div>
                <div className="reviewer-info">
                  <h3>Musaad Alturaif</h3>
                  <h5>Traveller</h5>
                  <p>
                    We really enjoyed “Discover Tabuk” trip with SJH,
                    experienced and Proffessional team, strongly recommended.
                    <br />
                    <a
                      href="https://g.page/r/CYPSJvKs2fCVEB0/review"
                      target="_blank"
                    >
                      Read More
                    </a>
                  </p>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
        {/* =============== Review area end =============== */}
      </>
    );
  }
}

export default AboutReview;
