import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import guide1Img from "../../../assets/images/guide/MR MAJED AHMED ALAMIRI.jpg";
import guide2Img from "../../../assets/images/guide/Mr HasSan Alshehri.jpg";
import guide3Img from "../../../assets/images/guide/Mr Khalid Alqahtani.jpg";
import guide4Img from "../../../assets/images/guide/Mr Saud Alqahtani Tour Guide  (1).jpg";
// import guide5Img from "../../../assets/images/guide/MR SAUD ALQAHTANI .jpg";

import { Link } from "react-router-dom";

class GuideWrapper extends Component {
  render() {
    const guideWrapper = {
      items: 3,
      loop: true,
      margin: 25,
      smartSpeed: 1500,
      autoplay: false,
      dots: false,
      nav: true,
      navText: [
        "<i class='bx bx-chevron-left' ></i>",
        "<i class='bx bx-chevron-right'></i>",
      ],
      responsive: {
        0: {
          items: 1,
          nav: false,
          dots: false,
        },
        600: {
          items: 2,
          nav: false,
          dots: false,
        },
        1000: {
          items: 3,
          dots: false,
          nav: true,
          loop: true,
        },
      },
    };
    return (
      <>
        {/* ===============  Guide wrapper start =============== */}
        <div className="guide-wrapper mt-120">
               <div className="container">
                   <div className="row">
                       <div className="col-lg-12 col-md-12 col-sm-12">
                           <div className="section-head head-left pb-40">
                               <h5>Tour Guide</h5>
                               <h2>Experience expert guidance from our skilled tour guides</h2>
                           </div>
                       </div>
                   </div>

                   <OwlCarousel className="guide-slider owl-carousel"  {...guideWrapper}>
                   <div className="guide-card">
                           <div className="guide-thumb">
                               <img  src={guide3Img} alt="" className="img-fluid" />
                               <div className="guide-info">
                                   <strong>Mr Khalid <br></br>Alqahtani</strong>
                                   <p>Tour Guide</p>
                                  
                               </div>
                           </div>
                       </div>
                       
                       <div className="guide-card">
                           <div  className="guide-thumb">
                               <img  src={guide2Img} alt="" className="img-fluid" />
                               <div className="guide-info">
                                   <strong>Mr Hassan <br></br> Alshehri</strong>
                                   <p>Tour Guide</p>
                                 
                               </div>
                           </div>
                       </div>
                      
                       <div className="guide-card">
                           <div className="guide-thumb">
                               <img  src={guide4Img} alt="" className="img-fluid" />
                               <div className="guide-info">
                                   <strong>Mr Saud <br></br> Alqahtani</strong>
                                   <p>Tour Guide</p>
                                  
                               </div>
                           </div>
                       </div>
                       <div className="guide-card">
                           <div className="guide-thumb">
                               <img src={guide1Img} alt="" className="img-fluid" />
                               <div   className="guide-info">
                                   <strong>Mr Majid Ahmed Alamiri</strong>
                                   <p>Tour Guide</p>
                                   
                                   
                               </div>
                           </div>
                       </div>
                       {/* <div className="guide-card">
                           <div className="guide-thumb">
                               <img style={{height: "445px"}} src={guide5Img} alt="" className="img-fluid" />
                               <div className="guide-info">
                                   <strong>MR Saud <br></br>Alqahtani</strong>
                                   <p>Tour Guide</p>
                                  
                               </div>
                           </div>
                       </div> */}
                   </OwlCarousel>
               </div>
           </div>
        {/* ===============  Guide wrapper end =============== */}
      </>
    );
  }
}

export default GuideWrapper;
