import React, { Component } from "react";
import ModalVideo from "react-modal-video";


import about1Img from "../../../assets/images/UJKIHL.jpg";

import Img1 from "../../../assets/images/1.png"
import Img2 from "../../../assets/images/2.png"
import Img3 from "../../../assets/images/3.png"
import Img4 from "../../../assets/images/4.png"
import "./style.css"


class AboutWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  render() {
    const { isOpen } = this.state;
    return (
      <>
        {/* ===============  About wrapper area start =============== */}
        <div className="about-wrapper mt-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-12">
                <div style={{marginRight: "6vw"}} className="about-wrapper-left">
                  <div  className="about-img">
                    <img  src={about1Img} alt="" className="img-fluid" />
                  </div>
                  {/* <div className="about-video">
                    <img src={about2Img} alt="" className="img-fluid" />
                    <i
                      onClick={() => this.setState({ isOpen: true })}
                      class="flaticon-play-button-arrowhead"
                    ></i>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-5 col-md-12">
                <div className="about-wrapper-right section-head head-left">
                  <h2>About SJH TOURS</h2>
                  <p>
                    We Sjh tour is a part of the DMC (Destination Management
                    Company) based in Tabuk and established in 2017. We are a
                    full-service tourism company that organizes integrated trips
                    for leisure travelers seeking to experience the beauty and
                    wonder of fascinating tourist's destinations across the
                    kingdom of Saudi Arabia particularly within the region of
                    Tabuk.
                  </p>
                  <h3>Our Products & Services</h3>
                  <p>Sjh tours DMC offers a wide range of services and helps create tailor-made travel programs that are built based on an understanding of traveller aspirations and local market experience. Our services covering History, Culture, and Nature &Adventure to all. You’ll find the support you need to ensure highly competitive end products.</p>
                  

                  <ul className="about-list">
                    <li>
                      <i className="flaticon-double-checking" />
                      Itinerary planning
                    </li>
                    <li>
                      <i className="flaticon-double-checking" />
                      Inbound/Outbound tours.
                    </li>
                    <li>
                      <i className="flaticon-double-checking" />
                      Guided tours for individuals/groups
                    </li>
                    <li>
                      <i className="flaticon-double-checking" /> Educational
                      tours and experience.
                    </li>
                    <li>
                      <i className="flaticon-double-checking" /> Safari tours.
                    </li>
                    <li>
                      <i className="flaticon-double-checking" /> Event
                      management.
                    </li>
                    <li>
                      <i className="flaticon-double-checking" /> Airport meets&
                      greet service.
                    </li>
                    <li>
                      <i className="flaticon-double-checking" />{" "}
                      Private/business airport transfers.
                    </li>
                    <li>
                      <i className="flaticon-double-checking" /> Bilingual
                      translators.
                    </li>
                    <li>
                      <i className="flaticon-double-checking" />
                      Coach’s service.
                    </li>
                   
                    <li>
                      <i className="flaticon-double-checking" />
                      Intercity transfers.
                    </li>
                    <li>
                      <i className="flaticon-double-checking" /> Camping tours.
                    </li>
                    <li>
                      <i className="flaticon-double-checking" /> Diving tours.
                    </li>
                   
                  </ul>
                  {/* <div className="about-wrapper-btn">
                    <Link to={"#"} className="btn-common">
                      Read More
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* =============== achievement area start =============== */}
        <div className="achievement-area p-80 mt-10">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="section-head pb-10">
                  <h2>The Special services we provide to our travellers.</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className="col-lg-3 col-md-6 col-sm-6 wow fadeInLeft animated"
                data-wow-duration="1500ms"
                data-wow-delay="0ms"
              >
                <div style={{backgroundColor: 'transparent'}} className="achievement-card mt-30">
                  <div className="achievement-icon">
                  <img style={{width: "150px"}}  src={Img1} alt=""  />
                  </div>
                  <h5  style={{ fontSize: "20px",color:"black" }}>
                  Flight Ticket<br></br> Booking
                  
                  
                  </h5>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-6 col-sm-6 wow fadeInLeft animated"
                data-wow-duration="1500ms"
                data-wow-delay="200ms"
              >
                <div style={{backgroundColor: 'transparent'}} className="achievement-card mt-30">
                  <div className="achievement-icon">
                  <img style={{width: "150px"}}  src={Img2} alt=""  />
                  </div>
                  <h5 style={{ fontSize: "20px",color:"black" }}>
                  Hotel <br></br>Booking
                  
                  </h5>
                </div>
              </div>
              <div
               className="col-lg-3 col-md-6 col-sm-6 wow fadeInLeft animated"
                data-wow-duration="1500ms"
                data-wow-delay="400ms"
              >
                <div style={{backgroundColor: 'transparent'}} className="achievement-card mt-30">
                  <div className="achievement-icon">
                  <img style={{width: "150px"}}  src={Img3} alt=""  />
                  </div>
                  <h5 style={{ fontSize: "20px",color:"black" }}>
                  Luxury Caravan Accommodation
                  </h5>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-6 col-sm-6 wow fadeInLeft animated"
                data-wow-duration="1500ms"
                data-wow-delay="600ms"
              >
                <div style={{backgroundColor: 'transparent', color:"black"}} className="achievement-card mt-30">
                  <div className="achievement-icon">
                  <img style={{width: "150px"}} src={Img4} alt=""  />
                  </div>
                  <h5 style={{ fontSize: "20px",color:"black" }}>
                  Car Rental<br></br> Services
                  
                 
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* =============== achievement area end =============== */}
        <div className="about-wrapper mt-120">
          <div className="container">
            <div className="row">
          
              <div  className="col-lg-7 col-md-30">
                <div className="about-wrapper-left section-head head-left">
                  <h2 style={{marginBottom: '20px'}}>Pursuing Our Goals with Purpose</h2>
                  <h4>Our Passionate Team</h4>
                  <p>
                  All our staff is recruited and trained to represent a certain image and a level of professionalism that we always strive to keep and to reach. Our team is always on hand to help create tailor-made packages for our clients.
                  </p>
                  <h4>Our Mission</h4>
                  <p>Our mission is to create lifetime experiences and excitement for discovering new lands, cultures and making new friends..</p>
                  
                 <h4>Our Vision</h4>
                  <p>Our vision is to fuel freedom (the freedom to discover new places and along the way discover more of who we are) and we look to the future with the vision of national transformation 2030 and the development of the tourism and the urban approach in the kingdom.</p>
                    
                  </div>
                    </div>
                    <div className="col-lg-5 col-md-12">
                <div className="about-wrapper-right">
                  {/* <div className="about-img">
                    <img src={about1Img} alt="" className="img-fluid" />
                  </div> */}
                  
                  <div className="about-video">
                 
                  <iframe className="video-size" width="460" height="415" src="https://www.youtube.com/embed/_hLc4ny-2Tw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                  </div>
                </div>
              </div>
                    </div>
                    </div>
                    </div>
                    

        {/* <React.Fragment>
          <ModalVideo
            channel="youtube"
            autoplay
            isOpen={isOpen}
            videoId="-tJYN-eG1zk"
            onClose={() => this.setState({ isOpen: false })}
          />
        </React.Fragment> */}

        {/* ===============  About wrapper area end =============== */}
      </>
    );
  }
}

export default AboutWrapper;
